import tw, { styled } from "twin.macro"
import { Image } from "../../Image/Image"
import { RichText } from "../../RichText/RichText"
import { StyledContainer } from "../../Styled/Container"

export const Outer = styled(StyledContainer)`
  ${tw`py-4 bg-navy text-white flex flex-col md:flex-row`}
`

export const Inner = tw.div`md:w-1/2 md:px-3`

export const IconWithContent = tw.div`
  flex flex-row py-1
`

export const StyledIcon = styled(Image)`
  ${tw`w-4 h-4 mr-2`}
`

export const Content = tw.div`
  flex flex-col text-14 leading-1.42 justify-center
`

export const VisitOtherStores = styled(RichText)`
  ${tw`my-1`}
`

export const Row = tw.div`flex flex-col md:flex-row justify-between`

export const Brand = styled.div`
  ${tw`text-white mt-4 md:mt-1`}
`
