import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useImage } from "../../../hooks/useImage"

export const withMaintenanceFooter =
  Component =>
  ({ name = "MaintenanceFooter" }) => {
    const { getGatsbyImage } = useImage()
    const { maintenanceFooter } = useStaticQuery(graphql`
      query SANITY_MAINTENANCE_Footer {
        maintenanceFooter: sanitySettingMaintenance {
          maintenanceFooterContactNumber
          maintenanceFooterContactEmail
          maintenanceFooterContactIconImage: _rawMaintenanceFooterContactIconImage(
            resolveReferences: { maxDepth: 4 }
          )
          maintenanceFooterVisitStoreIconImage: _rawMaintenanceFooterVisitStoreIconImage(
            resolveReferences: { maxDepth: 4 }
          )
          maintenanceFooterShopRossiAndDriza: _rawMaintenanceFooterShopRossiAndDriza(
            resolveReferences: { maxDepth: 4 }
          )
        }
      }
    `)

    const {
      maintenanceFooterContactNumber,
      maintenanceFooterContactEmail,
      maintenanceFooterContactIconImage,
      maintenanceFooterVisitStoreIconImage,
      maintenanceFooterShopRossiAndDriza,
    } = maintenanceFooter || {}

    const contactIcon = getGatsbyImage(maintenanceFooterContactIconImage)
    const storeIcon = getGatsbyImage(maintenanceFooterVisitStoreIconImage)

    Component.displayName = name
    return (
      <Component
        number={maintenanceFooterContactNumber}
        email={maintenanceFooterContactEmail}
        content={maintenanceFooterShopRossiAndDriza}
        contactIcon={contactIcon}
        storeIcon={storeIcon}
      />
    )
  }
