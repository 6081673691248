import React from "react"

import { withMaintenanceSubscribe } from "./withMaintenanceSubscribe"
import {
  Form,
  Label,
  EmailWrapper,
  Email,
  SuccessMessage,
  Submit,
  Description,
  Agree,
  StyledRichText,
} from "./MaintenanceSubscribeStyles"
import { StyledFormErrors } from "../../Styled/Form"
import {
  ModalExternal,
  ModalDismissButton,
  ModalContentsWithLayout,
} from "../../Modal/Modal"

export const MaintenanceSubscribe = withMaintenanceSubscribe(
  ({
    email,
    handleChange,
    handleSubmit,
    loading,
    errors,
    title,
    description,
    placeholder,
    submitButtonText,
    success,
    successMessage,
    privacyPolicyContent,
    popupActive,
    setPopupActive,
  }): JSX.Element => (
    <ModalExternal isOpen={popupActive} setIsOpen={setPopupActive}>
      <Form autoComplete="off">
        <Label htmlFor={"email"}>{title}</Label>
        <Description>{description}</Description>
        <EmailWrapper>
          <Email
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
            placeholder={placeholder}
            required
          />

          <Submit
            type="button"
            disabled={loading || success}
            onClick={() => setPopupActive(true)}
          >
            {submitButtonText}
          </Submit>
        </EmailWrapper>
        {success && <SuccessMessage>{successMessage}</SuccessMessage>}
        {errors && <StyledFormErrors>{errors}</StyledFormErrors>}
      </Form>
      <ModalContentsWithLayout layout={"privacy"}>
        <StyledRichText>{privacyPolicyContent}</StyledRichText>
        <ModalDismissButton>
          <Agree wide onClick={handleSubmit}>
            I agree
          </Agree>
        </ModalDismissButton>
      </ModalContentsWithLayout>
    </ModalExternal>
  ),
)
