import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFunctions } from "../../../hooks/useFunctions"

export const withMaintenanceSubscribe = Component => ({
  name = "Subscribe",
}: any) => {
  const { customerSubscribe, loading, errors } = useFunctions()

  const [popupActive, setPopupActive] = useState(false)

  const { maintenanceSubscribe } = useStaticQuery(graphql`
    query SANITY_MAINTENANCE_SUBSCRIBE {
      maintenanceSubscribe: sanitySettingMaintenance {
        maintenanceFooterSubscribeTitle
        maintenanceFooterSubscribeDescription
        additionalMaintenanceSubscribeInputPlaceholder
        additionalMaintenanceSubscribeSubmitButtonText
        additionalMaintenanceSubscribeSuccessMessage
        maintenanceFooterPrivacyPolicyContent: _rawMaintenanceFooterPrivacyPolicyContent(
          resolveReferences: { maxDepth: 4 }
        )
      }
    }
  `)

  const {
    maintenanceFooterSubscribeTitle,
    maintenanceFooterSubscribeDescription,
    additionalMaintenanceSubscribeInputPlaceholder,
    additionalMaintenanceSubscribeSuccessMessage,
    additionalMaintenanceSubscribeSubmitButtonText,
    maintenanceFooterPrivacyPolicyContent,
  } = maintenanceSubscribe || {}

  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)

  const handleSubmit = async event => {
    event.preventDefault()
    const { customer } = await customerSubscribe(email)
    if (customer?.id) {
      setSuccess(true)
      setEmail("")
    }
  }

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  Component.displayName = name
  return (
    <Component
      email={email}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      errors={errors}
      title={maintenanceFooterSubscribeTitle}
      description={maintenanceFooterSubscribeDescription}
      placeholder={additionalMaintenanceSubscribeInputPlaceholder}
      submitButtonText={additionalMaintenanceSubscribeSubmitButtonText}
      success={success}
      successMessage={additionalMaintenanceSubscribeSuccessMessage}
      privacyPolicyContent={maintenanceFooterPrivacyPolicyContent}
      popupActive={popupActive}
      setPopupActive={setPopupActive}
    />
  )
}
