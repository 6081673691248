import tw, { styled } from "twin.macro"

import { Body } from "../../Styled/Text"
import { StyledButton } from "../../Styled/Button"
import { RichText } from "../../RichText/RichText"

export const Form = tw.form`
  flex flex-col mb-2-4 mlg:mb-4
`

export const Label = tw.label`
  text-25 mlg:text-28 leading-1.4 mlg:leading-1.35 font-bold uppercase tracking-10 mlg:tracking-2 mb-0-4 mlg:mb-0-8 text-center mlg:text-left
`

export const EmailWrapper = tw.div`
  flex flex-row
`

export const Email = styled.input`
  ${tw`border rounded flex-grow mr-0-8 py-1-2 px-1-6 text-16 md:text-14 leading-1.42 border-white border-opacity-10 bg-white bg-opacity-10`}
  ::placeholder {
    ${tw`text-white`}
  }
`

export const SuccessMessage = styled(Body)`
  ${tw`mt-0-8 text-white`}
`

export const Submit = styled.button`
  ${tw`border rounded border-white bg-white text-14 leading-1.28 font-bold uppercase py-1-2 px-2-4 text-navy`}
  :disabled {
    ${tw`opacity-80 cursor-not-allowed`}
  }
`

export const Description = tw.p`mx-auto max-w-25-7 mlg:mx-0 mlg:max-w-full text-14 leading-1.42 mb-2-4 text-center mlg:text-left text-white text-opacity-70`

export const Agree = styled(StyledButton)`
  ${tw`mt-2`}
`

export const StyledRichText = styled(RichText)`
  ${tw`text-navy`}
`
