import React from "react"

import { withMaintenance } from "./withMaintenance"
import {
  Outer,
  Inner,
  LandingPasswordWrapper,
  LandingPassword,
} from "./MaintenanceStyles"
import { MaintenanceForm } from "./Form/MaintenanceForm"
import { Main } from "../Main/Main"
import { HeroCard } from "../Sections/Hero/Card/HeroCard"
import { MaintenanceFooter as Footer } from "./Footer/MaintenanceFooter.tsx"

export const Maintenance = withMaintenance(
  ({ content, location, handleClick, active }): JSX.Element => (
    <Main>
      <Outer>
        <Inner>
          <HeroCard card={content} />
          <Footer />
        </Inner>
      </Outer>
      <LandingPasswordWrapper>
        <LandingPassword onClick={handleClick}>Use Password</LandingPassword>
        {active ? <MaintenanceForm location={location} /> : null}
      </LandingPasswordWrapper>
    </Main>
  ),
)
