import React from "react"

import { withMaintenanceFooter } from "./withMaintenanceFooter"
import {
  Outer,
  Inner,
  IconWithContent,
  StyledIcon,
  Content,
  VisitOtherStores,
  Row,
  Brand,
} from "./MaintenanceFooterStyles"
import { MaintenanceSubscribe as Subscribe } from "../Subscribe/MaintenanceSubscribe"
import { SocialMedia } from "../../SocialMedia/SocialMedia"
import { Icon } from "../../Icon/Icon"

export const MaintenanceFooter = withMaintenanceFooter(
  ({ number, email, content, contactIcon, storeIcon }) => (
    <Outer>
      <Inner>
        <Subscribe />
      </Inner>
      <Inner>
        <IconWithContent>
          <StyledIcon image={contactIcon} />
          <Content>
            <a href={`tel:${number}`}>{number}</a>
            <a href={`mailto:${email}`}>{email}</a>
          </Content>
        </IconWithContent>
        <IconWithContent>
          <StyledIcon image={storeIcon} />
          <Content>
            <a>Visit our store</a>
          </Content>
        </IconWithContent>
        <VisitOtherStores>{content}</VisitOtherStores>
        <Row>
          <SocialMedia hideTitle />
          <Brand>
            <Icon name={"logo"} width="150" title={"RB Sellars brand logo"} />
          </Brand>
        </Row>
      </Inner>
    </Outer>
  ),
)
