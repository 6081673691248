import React, { useState } from "react"

export const withMaintenance = Component => ({
  name = "Maintenance",
  page,
  location,
}) => {
  const [active, setActive] = useState(false)

  const { content } = page || {}

  const handleClick = () => setActive(prev => !prev)

  Component.displayName = name
  return (
    <Component
      content={content}
      location={location}
      active={active}
      handleClick={handleClick}
    />
  )
}
