import tw, { styled } from "twin.macro"

export const Outer = tw.div`
  min-h-full-vh bg-navy
`

export const Inner = tw.div``

export const LandingPasswordWrapper = tw.div`
  bg-navy
`

export const LandingPassword = styled.button`
  ${tw`text-navy`}
`
