import React from "react"
import { graphql } from "gatsby"

import { Maintenance as Page } from "../components/Maintenance/Maintenance"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanitySettingMaintenance {
      password
      enabled
      content: _rawContent(resolveReferences: { maxDepth: 4 })
    }
  }
`

const Component = ({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("password_page")

  return <Page {...props} {...data} />
}
export default Component
